import React from 'react';
import TextToggle from '../../components/text-toggle/text-toggle';
import WoodHome from '../../images/wood-home-ready.png';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import Advantages from '../../components/advantages/advantages';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import Data from '../../data/data';
import PriceList from '../../components/price-list/price-list';


const RestavratsiyaFundamenta = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Реставрация деревянных домов', url:'/restavratsiya-derevyannyh-domov/', status:true},
    {name:'Реставрация фундамента', url:'#', status:true}]
  return (
    <div>
        <SEO title="Реставрация фундамента деревянного дома в Калининградской области"
            description="Опытные бригады в срок выполнят работы по реставрации фундамента деревянного дома или сруба в Калининградской области.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Реставрация фунда&shy;мента деревянного дома в Кали&shy;нинград&shy;ской области'} 
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
            <Container fluid>
        <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify">
                <h1>Реставрация фундамента</h1>
                <p>
                Ремонт, частичный или полная замена фундамента – процесс сложный, требующий профессионального мастерства.<br />

Профессионализм требуется уже на этапе диагностики существующего фундамента и его проблем, проведения расчетов и принятия решения проводить полную или частичную замену, или его усиление.<br />

При этом обязательно специалисты учитывают конструктивные особенности дома, рельефа местности, тип и несущую способность грунта.</p>.
                  
                  
               
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={WoodHome} className="w-100" style={{borderRadius:"0px 0px 150px 14px"}}  alt="" title="" />
              </Col>
            </Row>
        </Container>
        <Container fluid className="">
         <Advantages />
         </Container>
         
           
        <FormQuickOrder className="mt-5" />
        <ImagesView />
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default RestavratsiyaFundamenta;